import { useState, useRef, useEffect } from 'react'
import PhoneInput from './NumberInput'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import useEnhancedEffect from '@mui/utils/useEnhancedEffect'


function TomControles({
  setDialed,
  dialed,
  placeCall, 
  session,
  phoneSettings
}) {
  const [ activeInput, setActiveInput ] = useState('target')
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column',
        minHeight: '10em'
      }}
    >
      <PhoneInput 
        setValue={setDialed}
        value={dialed.target}
        placeCall={placeCall}
        session={session}
        setActiveInput={setActiveInput}
        dialType='target'
        title='Target Number'
      />
      <PhoneInput 
        setValue={setDialed}
        value={dialed.cid}
        placeCall={placeCall}
        session={session}
        setActiveInput={setActiveInput}
        dialType='cid'
        title={phoneSettings.dialType === 'autoDial' ? 'Starting caller ID' : 'Outgoing caller ID'}
      />
      <Divider />
    </Box>
  )
}


export default TomControles
