import Collapse from '@mui/material/Collapse'
import Switch from '@mui/material/Switch'
import Box from '@mui/material/Box'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

function SettingsPanel({ phoneSettings, setPhoneSettings, showSettings }) {
  
  function handleDialType(event, value) {
    setPhoneSettings((prev) => {
      return {
        ...prev,
        dialType: value
      }
    })
  }

  function handleToggle(event) {
    setPhoneSettings((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.checked
      }
    })
  }

  //console.log(phoneSettings)

  return (
    <Collapse orientation="horizontal" in={showSettings}>
      {showSettings &&
        <Box 
          visa
          sx={{ width: '100%', minWidth: '100%' }}
        >
          <ToggleButtonGroup
            color="primary"
            value={phoneSettings.dialType}
            exclusive
            onChange={handleDialType}
          >
            <ToggleButton value="default">Manual Dial</ToggleButton>
            <ToggleButton value="autoDial">Auto Dial</ToggleButton>
          </ToggleButtonGroup>
          {phoneSettings.dialType === 'default' && 
            <FormGroup>
              <FormControlLabel control={<Switch checked={phoneSettings.clearCidAfterCall} />} 
                label='Clear target/caller ID after call'
                name='clearCidAfterCall'
                onChange={handleToggle}
              />
            </FormGroup>
          }
          {phoneSettings.dialType === 'autoDial' && 
            <FormGroup>
              <FormControlLabel control={<Switch checked={phoneSettings.autoCall} />} 
                label='Auto call'
                name='autoCall'
                onChange={handleToggle}
              />
              <FormControlLabel disabled={!phoneSettings.autoCall} 
                control={<Switch checked={phoneSettings.pauseBetween && phoneSettings.autoCall ? true : false} />} 
                label='Pause between calls'
                name='pauseBetween'
                onChange={handleToggle}
              />
            </FormGroup>
          }
        </Box>
      
      }
    </Collapse>
  )
}

export default SettingsPanel


