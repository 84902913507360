import { useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import LoginButton from './LoginButton'
import { useAuth0 } from "@auth0/auth0-react"
import CircularProgress from '@mui/material/CircularProgress'

function LoginForm() {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0()
  
   useEffect(() => {
    console.log('Is loading!')
    console.log(isLoading)
  }, [isLoading])

  return (
    <Box 
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Typography variant="h2">
        Welcome to Jokephone
      </Typography>
      {isLoading ? <CircularProgress /> : <LoginButton />}
    </Box>
  )
}

export default LoginForm