import { Fragment, useState } from 'react'
import Backdrop from '@mui/material/Backdrop'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import WidgetBlocker from '../Components/WidgetBlocker'

function RegisterPrompt({ showLogin, setShowLogin, settings }) {
  return (
    <WidgetBlocker
      open={showLogin}
    >
      {settings ? 
        <Button variant="contained" onClick={() => setShowLogin(!showLogin)}>
          Go online
        </Button>
        :
        <CircularProgress />
      }
    </WidgetBlocker>
  )
}

export default RegisterPrompt