import Backdrop from '@mui/material/Backdrop'


function WidgetBlocker({ children, open }) {

  return(
    <>
      <Backdrop 
        sx={{
          zIndex: 1,
          position: 'absolute'
        }}
        open={open}
      >
        {children}
      </Backdrop>
    </>
  )
}

export default WidgetBlocker