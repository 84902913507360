import Paper from '@mui/material/Paper'
import CallIcon from '@mui/icons-material/Call'
import CallEndIcon from '@mui/icons-material/CallEnd'
import DialerSipIcon from '@mui/icons-material/DialerSip'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { useTheme } from '@mui/styles'
function ControleButtons({ 
  testCall, 
  answer, 
  placeCall, 
  endCall 
}) { 
  const theme = useTheme()
  return (
    <>
      <Divider />
      <Box
        display="flex"
        justifyContent="space-around"
      >
        <IconButton 
          onClick={endCall}
          sx={{
            padding: '10px'
        }}>
          <CallEndIcon fontSize="large" sx={{color: "red"}}/>
        </IconButton>
        <IconButton 
          //An arrow function is used to avoid sending the on click event
          onClick={() => placeCall()}
          sx={{
            padding: '10px'
          }}>
          <CallIcon fontSize="large" sx={{color: "green"}}/>
        </IconButton>
      </Box>
    </>
  )
}

export default ControleButtons