import { useState } from 'react'

const useDial = () => {
  // Initialize the state
  const [ dialed, setDialed ] = useState({cid: "", target: "", onCall: ""})
  
  const dialedSan = (input, dialedType, isDialPad = false) => {
    let output = null
    let cleaned = input.match(/[0-9a-zA-Z*#]*/gm).reduce((str, s) => {
      if (s.length > 0) {
        str += s
      }
      return str
    }, '')
    cleaned = convertPhoneNumberToDigits(cleaned)
    output = isDialPad ? `${dialed[dialedType]}${cleaned}` : cleaned
    setDialed((prev) => {
      return {
        ...prev,
        [dialedType]: output
      }
    })
  } 
  
  function resetDialed() {
    setDialed({cid: "", target: "", onCall: ""})
  }

  return [dialed, dialedSan, resetDialed]
}

function convertPhoneNumberToDigits(input) {
  let phonenumber = ''
  input = input.toLowerCase()
  input.split('').forEach((c) => {
    switch(c) {
      case '0': phonenumber+="0";break;
      case '1': phonenumber+="1";break;
      case '2': phonenumber+="2";break;
      case '3': phonenumber+="3";break;
      case '4': phonenumber+="4";break;
      case '5': phonenumber+="5";break;
      case '6': phonenumber+="6";break;
      case '7': phonenumber+="7";break;
      case '8': phonenumber+="8";break;
      case '9': phonenumber+="9";break;
      case '*': phonenumber+="*";break;
      case '#': phonenumber+="#";break;
      
      case 'a': case 'b': case 'c': phonenumber+="2";break;
      case 'd': case 'e': case 'f': phonenumber+="3";break;
      case 'g': case 'h': case 'i': phonenumber+="4";break;
      case 'j': case 'k': case 'l': phonenumber+="5";break;
      case 'm': case 'n': case 'o': phonenumber+="6";break;
      case 'p': case 'q': case 'r': case 's': phonenumber+="7";break;
      case 't': case 'u': case 'v': phonenumber+="8";break;
      case 'w': case 'x': case 'y': case 'z': phonenumber+="9";break;
      default: 
    } 
  })
  return phonenumber
}
// 1_-(207) 669-5568
function alphaToNum(character, phonenumber) {

  return phonenumber
}

export default useDial