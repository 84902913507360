class Tone {
	constructor(t, duration) {
  	this.AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext
  	this.context = new AudioContext()
    this.osc1 = this.context.createOscillator()
    this.osc2 = this.context.createOscillator()
    this.osc1.frequency.value = this.getFreq(t).f1
    this.osc2.frequency.value = this.getFreq(t).f2
		
    this.gainNode = this.context.createGain()
    this.gainNode.gain.value = 0.25
    
    this.filter = this.context.createBiquadFilter()
    this.filter.type = "lowpass";
    this.filter.frequency.setValueAtTime(8000, this.context.currentTime);
    
    this.osc1.connect(this.gainNode)
    this.osc2.connect(this.gainNode)
    
    this.gainNode.connect(this.filter)
    this.filter.connect(this.context.destination) 
    
    this.duration = duration ? duration : false
    this.playing = false  
  }
  
  getFreq(freq) {
    const dtmfFrequencies = {
      "1": {f1: 697, f2: 1209},
      "2": {f1: 697, f2: 1336},
      "3": {f1: 697, f2: 1477},
      "4": {f1: 770, f2: 1209},
      "5": {f1: 770, f2: 1336},
      "6": {f1: 770, f2: 1477},
      "7": {f1: 852, f2: 1209},
      "8": {f1: 852, f2: 1336},
      "9": {f1: 852, f2: 1477},
      "*": {f1: 941, f2: 1209},
      "0": {f1: 941, f2: 1336},
      "#": {f1: 941, f2: 1477},
      "r": {f1: 440, f2: 480},
      "b": {f1: 425, f2: 425},
      "h": {f1: 456, f2: 456}
    }
    return dtmfFrequencies[freq]
  }
  
  start() {
    this.playing = true
    this.osc1.start()
    this.osc2.start()
    if (this.duration) {
      console.log('Duration found', this.duration)
      setTimeout(() => {
      	this.stop()
      }, this.duration)
    }
  }
  stop(stop = false) {
    this.osc1.stop()
    this.osc2.stop()
    this.playing = false
  } 
}

class ToneLooper {
  constructor(tone, toneDur, repeatInt) {
    this.tone = tone
    this.toneDur = toneDur
    this.repeatInt = repeatInt
    this.loop = null
  }
  start() {
    this.current = new Tone(this.tone, this.toneDur)
    this.current.start()
    this.loop = setInterval(() => {
      this.current = new Tone(this.tone, this.toneDur)
      this.current.start()
    }, this.repeatInt) 
  }
  stop() {
    this.current.stop()
    clearInterval(this.loop)
  }
}

export {Tone, ToneLooper}