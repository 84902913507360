import { Fragment, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import IconButton from '@mui/material/IconButton'
import PauseRounded from '@mui/icons-material/PauseRounded'
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded'
import GraphicEqOutlinedIcon from '@mui/icons-material/GraphicEqOutlined'
import DownloadIcon from '@mui/icons-material/Download'
import { formatPhoneNumber } from '../utils.js'
import DeleteFiles from './Delete.js'
import SvgIcon from '@mui/material/SvgIcon'
import { ReactComponent as ZipIco } from  '../icons/ZipIcon.svg'
import ZipDownloader from './ZipDownloader'

console.log(ZipIco)

function Recordings({ callRecords, socket, setUrls, paused, setPaused, setDialed }) {
  const [ playing, setPlaying ] = useState(0)
  const [ deleteRequest, setDeleteRequest ] = useState([])
  const [ downloadingZip, setDownloadingZip ] = useState(false)
  const [ zipFile, setZipFile ] = useState(null)

  async function play(call) {
    try {
      const {recordings, bucket} = call
      const result = await Promise.all(recordings.map(key => getCallRecordings(key, bucket)))
      const recs = result.map(r => {
        if (r.name.includes('-in.wav')) {
          r.channel = "Remote"
        } else if (r.name.includes('-out.wav')) {
          r.channel = "Local"
        } else if (r.name.includes('-noSounds.wav')) {
          r.channel = "Mixed no sounds"
        } else {
          r.channel = "Mixed"
        }
        return r
      })
      setPlaying(recs)
      setUrls(recs)
    } catch (error) {
      console.log(error)
    }
  }
  
  async function handleDownload(call, getAllTracks = false) {
    try {
      console.log('Getting download', call, getAllTracks)
      
      const channels = call.recordings.map(r => {
        if (r.includes('-in.wav')) {
          return {
            channel: "Remote",
            key: r
          }
        } else if (r.includes('-out.wav')) {
          return {
            channel: "Local",
            key: r
          }
        } else if (r.includes('-noSounds.wav')) {
          return {
            channel: "Mixed no sounds",
            key: r
          }
        } else {
          return {
            channel: "Mixed",
            key: r
          }
        }
      })
      
      if (getAllTracks) {
        setDownloadingZip(true)
        console.log('Reequesting download zip')
        const zip = await getZip(call)
        console.log('Zip URL returned', zip)
        setZipFile(zip)
      } else {
        const mixed = channels.find(r => r.channel === "Mixed")
        if (mixed) {
          console.log(mixed)
          const result = await getCallRecordings(mixed.key, call.bucket)
          const url = result.url ? result.url : null
          openDownloadLinkInNewTab(url)
        } else {
          console.log('Mixed channel not found')
        }
      }

    } catch (error) {
      console.log(error)
    }
  }

  function openDownloadLinkInNewTab(url) {
    if (url) {
      console.log('Opening', url)
      window.open(url, "_blank")
    } else {
      console.log('No download URL found')
    }
  }

  function triggerZipDL() {
    openDownloadLinkInNewTab(zipFile.url)
    setDownloadingZip(false)
    setZipFile(null)
  }

  function getZip(call) {
    return new Promise((resolve, reject) => {
      try {
        const payload = {
          destBucket: "jfzips",
          srcBucket: call.bucket,
          destination_key: `${call.id}.zip`,
          files: call.recordings.map(r => {
            return {
              uri: r,
              filename: r,
              type: 'file'
            }
          })
        }
        socket.emit('getZipUrl', payload, (error, zip) => {
          if (error) throw error
          resolve(zip)
        })
      } catch (error) {
        reject(error)
      }
    }) 
  }

  function getCallRecordings(key, bucket) {
    return new Promise((resolve, reject) => {
      try {
        socket.emit('getCallRecordings', {key, bucket}, (error, url) => {
          if (error) throw error
          resolve(url)
        })
      } catch (error) {
        reject(error)
      }
    })
  }
  
  const columns = [
    { 
      field: 'filetype', 
      headerName: '', 
      width: 25,
      renderCell: () => <GraphicEqOutlinedIcon />
    },
    { field: 'target', headerName: 'Target', minWidth: 150 },
    { field: 'cid', headerName: 'Caller ID', minWidth: 150 },
    { field: 'bizName', headerName: 'Business Name', minWidth: 200 },
    {
      field: "play",
      headerName: "Actions",
      sortable: false,
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        let hasRecordings = false 
        if (params.row && params.row.recordings && params.row.recordings.length === 4) {
          hasRecordings = true
        }
        return (
          <Fragment>
            <IconButton
              aria-label={paused ? 'play' : 'pause'}
              onClick={() => play(params.row)}
              disabled={!hasRecordings}
            >
              {!paused && params.row.date === playing.date ? (
                <PauseRounded />
              ) : (
                <PlayArrowRounded /> 
              )}
            </IconButton>
            <IconButton
              disabled={!hasRecordings}
              onClick={() => handleDownload(params.row)}
            >
              <DownloadIcon />
            </IconButton>
            <IconButton
              disabled={!hasRecordings}
              onClick={() => handleDownload(params.row, true)}
            >
              <ZipIcon />
            </IconButton>
            <DeleteFiles 
              deleteRequest={deleteRequest}
              setDeleteRequest={setDeleteRequest}
              socket={socket}
              params={params}
            />
          </Fragment>
        )
       }
    }
  ]

  return (       
    <div style={{ height: 500, width: '100%' }}>
      <ZipDownloader 
        open={downloadingZip} 
        download={triggerZipDL}
        zip={zipFile}
      />
      <DataGrid
        density='compact'
        disableSelectionOnClick={true}
        onCellDoubleClick={(params, event) => {
          if (params.field === 'cid' || params.field === 'target') {
            setDialed(params.value, params.field)
          }
        }}
        rows={callRecords.map((c, i) => {
          return {
            cid: formatPhoneNumber(c.cid),
            target: formatPhoneNumber(c.to),
            id: `${c.date}-${c.cid}-${c.to}`,
            recordings: c.recordings,
            bizName: c.bizInfo.length > 0? c.bizInfo[0].name : '',
            date: c.date,
            bucket: c.bucket 
          }
        })}
        sx={{boarder: 'none'}}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5]}
      />
    </div>
  )
}

function parseEpoch(epoch) {
  return new Date(epoch * 1000)
}

function ZipIcon() {
  return (
    <SvgIcon 
      component={ZipIco}
      viewBox="0 0 55 55"
    />
  )
}

export default Recordings