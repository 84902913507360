//import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import React, { useState, useEffect, useRef } from 'react'
import io from 'socket.io-client'
import SimpleDialogDemo from './LoginForm'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import RecordingList from './Calls/Recordings'
import Player from './Calls/Player'
import { styled } from '@mui/material/styles'
import TopBar from './TopBar'
import PhoneBase from './Phone/PhoneBase'
import LoginForm from './LoginForm'
import { useAuth0 } from "@auth0/auth0-react"
import useDial from './Phone/hooks/useDial'
import SoundBoard from './Components/Builders/Soundboard/Soundboard'


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
})

darkTheme.typography.h3 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [darkTheme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
}
darkTheme.typography.h2 = {
  fontSize: '2.0rem',
  '@media (min-width:600px)': {
    fontSize: '2.5rem',
  },
  [darkTheme.breakpoints.up('md')]: {
    fontSize: '3.5rem',
  },
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

function App() {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0()
  //const [ recordings, setRecordings ] = useState([])
  const [ callRecords, setCallRecords ] = useState([])
  const [ socket, setSocket ] = useState(null)
  //const [ playerRef, setPlayerRef ] = useState(null)
  const [ playingUrls, setPlayingUrls ] = useState([])
  const [ paused, setPaused ] = useState(true)
  const [ token, setToken ] = useState(null)
  const [ dialed, setDialed, resetDialed ] = useDial()
  const [ settings, setSettings ] = useState(null)
  // const theme = useTheme()
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  
  useEffect(() => {
    const setupWS = async () => {
      try {
        const accessToken = await getAccessTokenSilently()
        setToken(accessToken)
        console.log('Making a new socket!')
        const newSocket = io(`/`, {
          query: {token: accessToken}
        })
        setSocket(newSocket)
        
        newSocket.on('recordings', (recordings) => {
          console.log(recordings)
          //setRecordings(recordings)
          setCallRecords(recordings)
        })
        newSocket.on('settings', (settings) => {
          setSettings(settings)
        })
      } catch (e) {
        console.log(e.message);
      }
    }
    if (isAuthenticated) setupWS()
  }, [setSocket, isAuthenticated])
  
  return (
    <div id="dls">
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Box>
          {isAuthenticated ? 
            <React.Fragment>
              <TopBar />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={8} xl={4} order={{xs: 2, sm: 1}}>
                  <Item sx={{
                    position: 'relative'
                  }}>
                    <RecordingList 
                      callRecords={callRecords} 
                      socket={socket} 
                      setUrls={setPlayingUrls} 
                      paused={paused} 
                      setPaused={setPaused}
                      setDialed={setDialed} 
                    />
                  </Item>
                  <Item>
                    <Player 
                      paused={paused} 
                      setPaused={setPaused} 
                      recordings={playingUrls}
                    />
                  </Item>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2} xl={2} order={{xs: 1, sm: 2}}>
                  <Item sx={{
                    zIndex: 1,
                    position: 'relative'
                  }}>
                    <PhoneBase 
                      dialed={dialed}
                      setDialed={setDialed}
                      resetDialed={resetDialed}
                      settings={settings}
                    />
                  </Item>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2} xl={2} order={{xs: 1, sm: 2}}>
                  <Item sx={{
                    zIndex: 1,
                    position: 'relative'
                  }}>
                    {/* <SoundBoard 
                      dialed={dialed}
                      settings={settings}
                    >
                    </SoundBoard> */}
                  </Item>
                </Grid>
              </Grid>
            </React.Fragment>
            :
            <LoginForm />
          }
        </Box>
      </ThemeProvider>
    </div>
  )
}

export default App
