import Buttom from "@mui/material/Button"
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Buttom 
      onClick={() => loginWithRedirect()}
      variant="contained"
      size="large"
    >
      Log In
    </Buttom>
  )
};

export default LoginButton;