
import ButtonBase from '@mui/material/ButtonBase'
import Button from '@mui/material/Button'
import { IconButton } from '@mui/material'
function PhoneButton({ num, dialed, setDialed }) {
  function dial(num) {
    setDialed(num, 'target', true)
  }
  
  return (
    <div>
      <ButtonBase
        centerRipple={true}
        sx={{
          width: '100%',
          height: '100%'
        }}
        onClick={() => dial(num)}
      >
        <h1>
          {num}
        </h1> 
      </ButtonBase>  
    </div>
  )
}

export default PhoneButton