import * as React from 'react'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'

export default function YesNo({
  title, 
  text,
  open,
  handleConfirm, 
  handleCancel,
  inProgress
}) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleCancel}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button 
          autoFocus 
          onClick={handleCancel}
          variant="contained"
        >
          Cancel
        </Button>
        <LoadingButton 
          onClick={handleConfirm} 
          color="error"
          variant="contained"
          loading={inProgress}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
