import { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import YesNo from './YesNo'

function DeleteFiles({
  deleteRequest,
  setDeleteRequest,
  socket,
  params
}) {
  const [ showDelete, setShowDelete ] = useState(false)
  const [ deleting, setDeleting ] = useState(false)
  
  function handleDelete() {
    setDeleting(true)
    console.log(params.row.key)
    socket.emit('deleteRecordings', {
      keys: params.row.recordings, 
      date: params.row.date,
      bucket: params.row.bucket
    }, () => {
      setDeleting(false)
      setShowDelete(false)
    })
  }
  
  function handleCancel() {
    setDeleteRequest([])
    setShowDelete(false)
  }

  return (
    <>
      {showDelete &&
        <YesNo 
          open={showDelete}
          title={`Are you sure you want to delete ${deleteRequest.length > 0 ? 'sound files?' : 'a sound file?'}`}
          text={"Delete this recording permanently?"}
          handleCancel={handleCancel}
          handleConfirm={handleDelete}
          inProgress={deleting}
        />
      }
      <IconButton
        onClick={() => setShowDelete(!showDelete)}
      >
        <DeleteForeverIcon />
      </IconButton>
    </>
  )
}

export default DeleteFiles