import { useEffect, useState, Fragment, useRef } from 'react'
import JsSIP from 'jssip'
import Grid from '@mui/material/Grid'
import PhoneButton from './PhoneButton'
import Paper from '@mui/material/Paper'
import TopControles from './TopControles'
import ControleButtons from './ControleButtons'
import RegisterPrompt from './RegisterPrompt'
import SettingsIcon from '@mui/icons-material/Settings'
import { IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

import { Tone, ToneLooper } from './Tones'
import SettingsPanel from './SettingsPanel'
//Static audio files



function PhoneBase({ dialed, setDialed, resetDialed, settings }) {
  const [ socket, setSocket ] = useState(null)
  const [ phone, setPhone ] = useState(null)
  const [ session, setSession ] = useState(null)
  const [ remoteAudio, setRemoteAudio ] = useState(null)
  const [ showLogin, setShowLogin ] = useState(true)
  const [ phoneSettings, setPhoneSettings ] = useState({
    dialType: 'default',
    clearCidAfterCall: true
  })
  const [ showSettings, setShowSettings ] = useState(false)
  // Used to give access to updated states in callback functions
  const stateRef = useRef({phoneSettings, dialed})

  let loopingAudio = null
  
  useEffect(() => { 
    if (remoteAudio && settings) {
      window.onbeforeunload = () => {
        console.log('Refresh, ending calls.')
        endCall()
      }
      const newSocket = new JsSIP.WebSocketInterface(`wss://${process.env.REACT_APP_PBX_URL}:8089/ws`);
    
      const configuration = {
        sockets  : [ newSocket ],
        uri      : `sip:${settings.extension}@${process.env.REACT_APP_PBX_URL}`,
        password : `${settings.secret}`
      }
      const newPhone = new JsSIP.UA(configuration)
      setPhone(newPhone)
      //console.log('Phone set', phone, newPhone)
      newPhone.start()
      console.log('Start ran')
      newPhone.on('registered', (e) => {
        console.log('registered')
      })
      newPhone.on('registrationFailed', (e) => {
        console.log('registrationFailed')
      })
      newPhone.on('getusermediafailed', e => {
        console.log('Get user media failed')
        console.log(e)
      })
      newPhone.on('newRTCSession', (e) => {
        const session = e.session
        console.log('session time')
        if (session && session.direction === 'incoming') {
          setSession(session)
          console.log('Incoming call')
          session.on('ended' , sessionEnded)
          session.on('peerconnection', function(data) {
            console.log("Peer connection")
            waitForStream(data.peerconnection)
          })
          session.answer({mediaConstraints: {audio: true, video: false}})
        } else {
          //waitForStream(session.connection)
        }
      })
      return () => {
        window.onbeforeunload = null
        endCall()
      }
    }
  }, [remoteAudio])


  useEffect(() => {
    // Write to local storage
    if (phoneSettings) {
      stateRef.current.phoneSettings = phoneSettings
    } 
  }, [phoneSettings])
  useEffect(() => {
    // Write to local storage
    if (dialed) {
      stateRef.current.dialed = dialed
    } 
  }, [dialed])

  // Watch for button preses on call to send dtmf
  useEffect(() => {
    if (session) {
      const lastNum = dialed.target.substr(dialed.target.length -1, dialed.target.length)
      console.log('Button pressed, sending DTMF', lastNum)
      session.sendDTMF(lastNum)
    }
  }, [dialed.target])

  function waitForStream(src) {
    console.log(src)
    src.addEventListener('addstream', (e) => {
      remoteAudio.srcObject = e.stream
      remoteAudio.play()
    })
  }

  function answer(stream) {
    if (remoteAudio) {
      console.log('answer')
      remoteAudio.srcObject = stream
      remoteAudio.play()
    } else {
      console.log('No remote audio')
    }
  }

  function placeCall(options) {  
    const overides = options ? options : {cid: null, target: null}
    console.log('Attempting to call', dialed.target)
    if (!session) {
      let outboundCid = null
      console.log("=222222222222222222222222222222222222222222222222222222")
      console.log(overides, "Call overides")
      if (overides.cid) {
        outboundCid = overides.cid
      } else if (dialed.cid.length > 0) {
        outboundCid = dialed.cid
      } else {
        console.log('Using default CID', dialed.cid)
        outboundCid = '9706654505'
        setDialed(outboundCid, 'cid')
      }
      const options = {
        eventHandlers: {
          progress: (e) => {  
            console.log('Progress')
            if (e.response.reason_phrase === 'Ringing') { 
              loopingAudio = new ToneLooper('r', 2300, 6000)          
              loopingAudio.start()
            }
            console.log(e)
          },
          ended: sessionEnded,
          confirmed: () => {
            console.log('confirmed')
            if (loopingAudio) loopingAudio.stop()
          },
          getusermediafailed: (e) => {
            console.log("getusermediafailed")
            console.log(e)
          },
          failed: (e) => {
            console.log('Call failed')
            endCall()
          }
        },
        extraHeaders: [`X-cid: ${outboundCid}`],
        'mediaConstraints' : { 'audio': true, 'video': false }
      };
      let target = dialed.target

      const session = phone.call(overides.target ? overides.target : target, options)                                     
      if (session) {
        setSession(session)
        waitForStream(session.connection)
      }
      
    } else {
      //todo warn user is they are going to end an in progress call
      console.log('Call already in progress')
    }
    console.log('placing call')
    console.log(dialed.target)
  }
 
  function sessionEnded() {
    console.log('SESSION ENDED')
    endCall()
  }

  function endCall() {
    console.log('Ending call', session ? true : false)
    if (session) {
      console.log(session)
      session.terminate()
    } 
    const endTone = new Tone('h', 500)
    if (loopingAudio) loopingAudio.stop()
    if(dialed.target.length > 0) endTone.start()
    setSession(null)
    if (phoneSettings.clearCidAfterCall && phoneSettings.dialType != 'autoDial') {
      resetDialed()
    }
    triggerAutoDial()
  }

  function triggerAutoDial() {
    console.log(phoneSettings)
    console.log(stateRef.current.phoneSettings, 'With ref')
    console.log(stateRef)
    console.log(stateRef.current.phoneSettings.dialType, stateRef.current.phoneSettings.dialType === 'autoDial')
    if (stateRef.current.phoneSettings.dialType === 'autoDial') {
      console.log('Auto Dial triggered')
      console.log(typeof stateRef.current.dialed.cid)
      let newCid = parseInt(stateRef.current.dialed.cid) 
      console.log(typeof newCid, 'New CID')
      newCid = newCid + 1
      console.log(newCid, 'New CID')
      setDialed(newCid.toString(), 'cid')
      if (stateRef.current.phoneSettings.autoCall) {
        setTimeout(() => {
          console.log('Placing call with cid of', newCid.toString())
          placeCall({cid: newCid.toString()})
        }, stateRef.current.phoneSettings.pauseBetween ? 3000 : 0)
      }
    }
  }

  function setupSip() {
    const rmAudio = new Audio()
    setRemoteAudio(rmAudio)
    setShowLogin(false)
  }


  // function overwriteDialed(value, target) {
  //   if (target === 'dialed') {
  //     setDialed(value)
  //   } else if (target === 'cid') {
  //     setCid(value)
  //   } else if (target === 'onCall') {
  //     setDialedOnCall(value)
  //   }
  // }

  return (
    <Fragment>
      <IconButton
        sx={{marginLeft: 'auto', display: 'block'}}
        onClick={() => setShowSettings(!showSettings)}
      >
        {showSettings ? <CancelIcon /> : <SettingsIcon />}
      </IconButton>
      <SettingsPanel 
        showSettings={showSettings}
        phoneSettings={phoneSettings}
        setPhoneSettings={setPhoneSettings}
      />
      <RegisterPrompt 
        showLogin={showLogin} 
        setShowLogin={setupSip}
        settings={settings}
      />
      <TopControles
          setDialed={setDialed}
          dialed={dialed}
          placeCall={placeCall} 
          session={session}
          phoneSettings={phoneSettings}
      />
      <Grid container spacing={0}>
        <Grid item xs={4} s={4} md={4} lg={4} direction='column'>
          <PhoneButton num="1" setDialed={setDialed} />
          <PhoneButton num="4" setDialed={setDialed} />
          <PhoneButton num="7" setDialed={setDialed} />
          <PhoneButton num="*" setDialed={setDialed} />
        </Grid>
        <Grid item xs={4} s={4} md={4} lg={4}>
          <PhoneButton num="2" setDialed={setDialed} />
          <PhoneButton num="5" setDialed={setDialed} />
          <PhoneButton num="8" setDialed={setDialed} />
          <PhoneButton num="0" setDialed={setDialed} />
        </Grid>
        <Grid item xs={4} s={4} md={4} lg={4}>
          <PhoneButton num="3" setDialed={setDialed} />
          <PhoneButton num="6" setDialed={setDialed} />
          <PhoneButton num="9" setDialed={setDialed} />
          <PhoneButton num="#" setDialed={setDialed} />
        </Grid>
      </Grid>
      <ControleButtons 
        placeCall={placeCall}
        answer={answer}
        endCall={endCall}
      />
    </Fragment>
  )
}

export default PhoneBase 