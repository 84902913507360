import { Fragment, useRef, createRef } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
//import TrapFocus from '@mui/core/Unstable_TrapFocus'

function NumberInput({ 
  value, 
  setValue, 
  placeCall,
  session,
  title,
  dialType,
  setActiveInput,
}) {

  return (
    <TextField
      type="tel" pattern="[0-9]*"  
      id={dialType}
      disabled={session ? true : false}
      onFocus={(e) => setActiveInput(dialType)}
      value={value}
      onChange={(e) => setValue(e.target.value, dialType)}
      onKeyPress={(e) => e.key === "Enter" ? placeCall() : null}
      label={title}
        InputProps={{
          endAdornment:
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={(e) => setValue('', dialType)}
              disabled={session ? true : false}
              edge="end"
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        }
      }
    >
    </TextField>
  )
}


export default NumberInput