import { useState } from 'react'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import WidgetBlocker from '../Components/WidgetBlocker'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

function ZipDownloader({ open, download, zip }) {
  return (
    <WidgetBlocker
      open={open}
    >
      {zip ? 
        <CenterBox>
          <Button variant="contained" onClick={() => download()}>
            Download 
          </Button>
        </CenterBox>
        :
        <CenterBox>
          <Typography variant="h2">
            Zipping files
          </Typography>
          <CircularProgress />
        </CenterBox>
      }
    </WidgetBlocker>
  )
}

function CenterBox({ children }) {
  return (
    <Box
      display='flex'
      color='white'
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
      //position='relative'
    >
      {children}
    </Box>
  )
}

export default ZipDownloader